import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavbarBrand = ({ to, children, className }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
)

const StyledNavbarBrand = styled(NavbarBrand).attrs({
  className: 'navbar-brand',
})``

export default StyledNavbarBrand
