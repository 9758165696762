import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Icon from './icon'
import Fleur from './fleur'

const Footer = () => (
  <footer className="footer">
    <Container>
      <Row>
        <Col sm="12" md="3">
          <h3>Contact Us</h3>

          <address>
            <p>Whickham Thorns</p>
            <p>Market Lane,</p>
            <p>Gateshead</p>
            <p>NE11 9NX</p>
          </address>
        </Col>
        <Col sm="12" md="3">
          <h3>Site Links</h3>

          <ul className="p-0 list-unstyled">
            <li>
              <a
                href="https://gatesheadscouts.org.uk/"
                rel="noreferrer"
                className="text-white"
              >
                Gateshead Scouts
              </a>
            </li>
            <li>
              <a
                href="https://scouts.org.uk/"
                rel="noreferrer"
                className="text-white"
              >
                Scouts UK
              </a>
            </li>
          </ul>
        </Col>
        <Col sm="12" md="3">
          <h3>Social</h3>

          <Icon
            href="https://www.facebook.com/whickhamthorns"
            icon={['fab', 'facebook-square']}
            rel="noreferrer"
          />

          <Icon
            href="https://twitter.com/whickhamthorns"
            icon={['fab', 'twitter']}
            rel="noreferrer"
          />

          <Icon
            href="https://www.instagram.com/whickhamthorns"
            icon={['fab', 'instagram']}
            rel="noreferrer"
          />
        </Col>
        <Col sm="12" md="3">
          <Fleur />
        </Col>
      </Row>

      <p>
        <small>
          Website developed by <a className="text-white" href="https://nathandunn.uk" rel="external" title="Nathan Dunn">Nathan Dunn</a>.
        </small>
      </p>
    </Container>
  </footer>
)

export default Footer
