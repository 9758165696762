import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { darken } from 'polished'

const Link = styled.a`
  color: #fff;
  font-size: 1.5rem;
  padding-right: 0.5rem;

  &:hover {
    color: ${darken(0.3, '#fff')};
  }
`

const Icon = ({ icon, ...props }) => {
  return (
    <Link {...props}>
      <FontAwesomeIcon icon={icon} />
    </Link>
  )
}

export default Icon
