import React from 'react'
import styled from 'styled-components'
import FleurDeLis from '../../images/fleur.svg'

const StyledFleur = styled.div`
  height: 8rem;
  background: url(${FleurDeLis}) no-repeat center;
  background-size: contain;
`

const Fleur = () => <StyledFleur />

export default Fleur
