import React, { Component } from 'react'
import Link from 'gatsby-link'
import { 
  Collapse, 
  Navbar, 
  NavbarToggler, 
  Nav, 
  NavItem, 
  UncontrolledDropdown, 
  DropdownToggle, 
  DropdownMenu,
} from 'reactstrap'
import PropTypes from 'prop-types'
import NavLink from './nav-link'
import NavbarBrand from './navbar-brand'

export default class MainNav extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div>
        <Navbar color="blue" dark expand="md">
          <NavbarBrand to="/">{this.props.title}</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/book">Book</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  About
                </DropdownToggle>
                <DropdownMenu right>
                  <Link className="dropdown-item" to="/volunteer">Volunteer</Link>
                  <Link className="dropdown-item" to="/jobs">Jobs</Link>
                  <Link className="dropdown-item" to="/shop">Shop</Link>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink to="/contact">Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

MainNav.propTypes = {
  title: PropTypes.string.isRequired,
}
