import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavLink = ({ to, children, className }) => (
  <Link className={className} to={to}>
    {children}
  </Link>
)

const StyledNavLink = styled(NavLink).attrs({
  className: 'nav-link',
})``

export default StyledNavLink
